/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $(document).click (function (e) {
          if (e.target !== $('.modal-dialog')[0]) {
            $('#complimentaryOffer').hide();
          }
        });

        function mobileMenuAppend() {
          var brand = $("<img>").attr('src', '/wp-content/uploads/2019/10/the-wonderbar-logo-header.png');
          var bookEvents = $("<div class='site-btn-wrapper'><a href='#' class='text-uppercase site-btn btn skewed-border' data-toggle='modal' data-target='#designMyNight'>Book Events</a></div>");
          var bookDarts = $("<div class='site-btn-wrapper'><a href='#' class='text-uppercase site-btn btn skewed-border' data-toggle='modal' data-target='#dartsEntertainment'>Book Darts</a></div>");
          var bookTables = $("<div class='site-btn-wrapper'><a href='#' class='text-uppercase site-btn btn skewed-border' data-toggle='modal' data-target='#bookTables'>Book Tables</a></div>");
          var socialMedia = $("<div class='mobile-social-media'><a href='https://www.facebook.com/TheWonderBarNewcastle' target='_blank\'><span class='twb-gold'><i class='fab fa-facebook-square'></i></span></a><a href='https://www.instagram.com/the_wonderbar/' target='_blank\'><span class='twb-gold'><i class='fab fa-instagram'></i></span></a></div>");

          $('#mega-menu-primary_navigation').prepend(brand);
          $('#mega-menu-primary_navigation').append(bookEvents);
          $('#mega-menu-primary_navigation').append(bookDarts);
          $('#mega-menu-primary_navigation').append(bookTables);
          $('#mega-menu-primary_navigation').append(socialMedia);
        }

        if ($(window).width() <= 1000){
          mobileMenuAppend();
        }

        // Add custom span element to menu item (skewed background-border)
        $('.mega-current-menu-item').append('<span class="active-page-border skewed-border"></span>');

        var menu_header = $('.menu-header');
        var collapse = $('.collapse');

        // Accordion collapse function
        $('.menu-header').click(function () {
          $(this).parent().find(collapse).slideToggle();

          $(menu_header).data('clicked', true);

          if($(collapse).is(':visible')) {
            $(this).parent().find(menu_header);
            $(this).addClass("twb-gold-bg");
            $(this).children('i').addClass("flipper");
          }

          if($(collapse).not(':visible')) {
            $(menu_header).children('i').removeClass("flipper");
          }

          $(this).parent().siblings().children().next().slideUp();
        });

        // Scroll animation for Decoration lines based on User Scroll position (Y-axis)
        function isScrolledIntoView(elem) {
          var docViewTop = $(window).scrollTop();
          var docViewBottom = docViewTop + $(window).height();

          var elemTop = $(elem).offset().top;
          var elemBottom = elemTop + $(elem).height();

          return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
        }

        $(window).scroll(function () {
          $('.decor-line').each(function () {
            if (isScrolledIntoView(this) === true) {
              $(this).addClass('fastAnimation');
            }
          });

        });

        // Initialise Animations
        function animateScript() {
          AOS.init();
        }

        animateScript();

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        const swiper = new Swiper('.swiper-container', {
          direction: 'horizontal',
          centeredSlides: true,
          autoplay: {
            delay: 5000,
          },
          loop: true,
          breakpoints: {
            // when window width is >= 320px
            320: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
            // when window width is >= 480px
            480: {
              slidesPerView: 2,
              spaceBetween: 15,
            },
            // when window width is >= 640px
            992: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            // when window width is >= 640px
            1200: {
              slidesPerView: 3,
              spaceBetween: 30,
            }
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },

    'food': {
      init: function() {
        // JavaScript to be fired on the food page

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
